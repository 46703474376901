import { useState, useEffect } from "react";
import MetaComponent from "../components/Meta";
import { BRAND_TAGLINE } from "../lib";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import app from "../firebase.config";
import Layout from "../components/Layout";
import Loader from "../components/Loader";

const db = getFirestore(app);
const Home = () => {
  const [items, setItems] = useState<any>(null);

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    const users: any = [];
    querySnapshot.forEach((doc) => {
      users.push(doc.data());
    });
    setItems(users);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <MetaComponent title={BRAND_TAGLINE} />
      <Layout>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Users</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your account including their name,
                title, email and user type.
              </p>
            </div>
            {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Add user
              </button>
            </div> */}
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                  {items ? (
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                          >
                            Name
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Type
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Sub Type
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Profile Photo
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Pitch Deck
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Video
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Video Description
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {items?.length > 0 ? (
                          items?.map((person: any, i: number) => (
                            <tr key={i}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                {person.name || "Unknown"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.email || "Unknown"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.type || "Unknown"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.sub_type || "Unknown"}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.photoDownloadUrl ? (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={person.photoDownloadUrl}
                                  >
                                    View Image
                                  </a>
                                ) : (
                                  "Unknown"
                                )}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.pitchDeck ? (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={person.pitchDeck}
                                  >
                                    View Deck
                                  </a>
                                ) : (
                                  "Unknown"
                                )}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.videoDownloadUrl ? (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={person.videoDownloadUrl}
                                  >
                                    View Video
                                  </a>
                                ) : (
                                  "Unknown"
                                )}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.videoDescription || "Unknown"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                              {"No Data Available"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <Loader text={"Loading users..."} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;

Home.auth = true;
