// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAjLMESRONarIw9Vj8zanVj36HZUKouCs8",
  authDomain: "africanwomenamplified.firebaseapp.com",
  projectId: "africanwomenamplified",
  storageBucket: "africanwomenamplified.appspot.com",
  messagingSenderId: "731098388803",
  appId: "1:731098388803:web:6eee71e15a76271fe8b7f6",
  measurementId: "G-8RRRFS5V3V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

export default app
